import React from 'react';

const footer = () => {
  return (
    <footer className=''>
      <div className='container text-light pt-5'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-2'>
            <div className='footer-title'>
              <h6>CST Solution</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <a href="/" className="nav-link">หน้าแรก</a>
                </li>
                <li >
                  <a href="/#Service" className="nav-link">บริการของเรา</a>
                </li>
                <li >
                  <a href="/product" className="nav-link">สินค้า</a>
                </li>
                <li >
                  <a href="/about" className="nav-link">เกี่ยวกับเรา</a>
                </li>
                <li >
                  <a href="/contact" className="nav-link">ติดต่อเรา</a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-2'>
            <div className='footer-title'>
              <h6>Service</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  Connectivity & IOT
                </li>
                <li >
                  Cyber Security
                </li>
                <li >
                  Client Computing
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-2'>
            <div className='footer-title'>
              <h6>Contact Us</h6>
            </div>
            <div className='footer-content'>
              <div className='text-muted'>
                <div><small>โทร. (+66) 0839538081</small></div>
                <div><small>Line. numpodang</small></div>
                <div><small>E-mail : kitsada.r@cst-solutionth.com</small></div>
              </div>
              <div className='social-media mt-4'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href='#' className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href='#' className='text-light'>
                  <i className='fab fa-twitter mr-4' />
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href='#' className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href='#' className='text-light'>
                  <i className='fab fa-github' />
                </a>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-2'>
            <div className='footer-title'>
              <h6>LOCATION</h6>
            </div>
            <div className='footer-content'>
              <div className='text-muted'>
                <small>CST SOLUTION COMPANY LIMITED</small>
                <p><small>เลขที 98/1 ตำบล แปลงยาว อำเภอ แปลงยาว จังหวัด ฉะเชิงเทรา 24190</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>Copyright 2021 © CST SOLUTION Co., Ltd.</small>
      </div>
    </footer>
  );
};

export default footer;
