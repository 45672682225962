import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { App } from './app';

// import './index.css';
// import './App.css';


import './assets/scss/bootstrap/bootstrap.scss';
import './assets/scss/front.scss';
import './assets/scss/main.scss';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
