import { Fragment } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"

export const About = () => {
    return (
        <Fragment>
            <Header navClass="bg-white" />
            <main className="content-container">
                <section id="AboutUs" className="section section-lg pt-4 pb-4">
                    <div className="container">
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 text-center">
                                <h2 className="h1 px-lg-5">เกี่ยวกับเรา</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p style={{ textIndent: '2.5em' }}>
                                    บริษัท ซีเอสที โซลูชั่น จำกัด เราให้บริการด้านการออกแบบ ติดตั้ง วางระบบ
                                    รวมถึงบริการดูแลระบบคอมพิวเตอร์
                                    โดยทีมงานมืออาชีพ และด้วยประสบการณ์ที่ยาวนาน ในวงการไอที
                                    เราจึงมุ่งมั่นพัฒนาตัวเองอย่างต่อเนื่องสู่มาตรฐานสากล
                                    เพื่อให้ลูกค้ามีความมั่นใจว่าสามารถตอบสนองต่อความต้องการของลูกค้าได้อย่างครบวงจร
                                    และมีประสิทธิภาพสูงสุด
                                </p>
                                <p style={{ textIndent: '2.5em' }}>
                                    จากปัจจัยต่างๆ นับต่อจากนี้ บริษัท คิดว่าเป็นช่วงเวลาสำคัญในการรับมือ
                                    และเปลี่ยนผ่านเข้าสู่ยุคดิจิตอลอย่างเต็มรูปแบบ
                                    ซึ่งส่งผลให้เกิดธุรกิจใหม่ๆได้ตลอดเวลาอย่างรวดเร็ว ทางทีมงาน พร้อมที่จะเป็นส่วนหนึ่ง
                                    ซึ่งจะนำความเป็นมืออาชีพเพื่อนำพาโอกาสของบริษัท
                                    และคู่ค้า สำหรับในการผ่านวิกฤต และสร้างโอกาสใหม่ๆ เข้าสู่ธุรกิจของลูกค้า
                                    และพันธมิตรได้อย่างมีประสิทธิภาพ
                                </p>
                                <p style={{ textIndent: '2.5em' }}>
                                    ทางผู้บริหาร ขอขอบคุณ ผู้ร่วมทุน คู่ค้า คู่ธุรกิจ  ในการร่วมและสนับสนุน
                                    การดำเนินงานของบริษัท รวมถึงขอบคุณเพื่อนพนักงานที่ร่วมกัน ทุ่มเทให้เกิดผลงานต่างๆ
                                    สำเร็จลุล่วงได้ และมีความเชื่อมั่นว่า บริษัทจะดำเนินธุรกิจพร้อมทั้งให้บริการลูกค้า
                                    และพัฒนาระบบงาน เพื่อเสริมสร้างความแข็งแกร่งต่อ ตัวเอง และความมั่นใจ
                                    เพื่อให้ลูกค้าได้รับประโยชน์สูงสุด
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>
    )
}