import { Fragment } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { ProductList } from "../components/ProductList"

export const Product = () => {
    return (<>
        <Fragment>
            <Header navClass="bg-white" />
            <main className="content-container">
                <ProductList />
            </main>
            <Footer />
        </Fragment>

    </>)
}