import { Fragment } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"

export const Contact = () => {
    return (<>
        <Fragment>
            <Header navClass="bg-white" />
            <main className="content-container">
                <section id="AboutUs" className="section section-lg pt-4 pb-4">
                    <div className="container">
                        <div className="row justify-content-center mb-4">
                            <div className="col-12">
                                <h2 className="h1 px-lg-5 text-center">ติดต่อเรา</h2>
                                <div className="h3 pt-4 px-lg-5 text-center">บริษัท ซีเอสที โซลูชั่น จำกัด</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 mb-5">
                                <div className="row">
                                    <div className="col-auto">
                                        <img
                                            src="https://static.wixstatic.com/media/86eaf9_ea601e5c4de2452cae76d9dd03eb883f~mv2.png/v1/fill/w_46,h_44,al_c,q_85,usm_0.66_1.00_0.01/phone%20png2.webp"
                                            alt="phone png2.png"
                                            className="mr-2"
                                            style={{ width: '52px', objectFit: 'cover', objectPosition: '50% 50%' }} />
                                    </div>
                                    <div className="col p-0">
                                        <span>0839538081 ทีมฝ่ายขาย ( Sales/Tele-Sales)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-5">
                                <div className="row">
                                    <div className="col-auto">
                                        <img
                                            src="https://static.wixstatic.com/media/86eaf9_762d847f463c47ba9c7b77c50b3636a3~mv2.png/v1/fill/w_52,h_40,al_c,q_85,usm_0.66_1.00_0.01/gmail.webp"
                                            alt="gmail.png"
                                            className="mr-2"
                                            style={{ width: '52px', objectFit: 'cover', objectPosition: '50% 50%' }} />
                                    </div>
                                    <div className="col p-0">
                                        <span>E-mail: kitsada.r@cst-solutionth.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 mb-5">
                                <div className="row">
                                    <div className="col-auto">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/LINE_logo.svg/300px-LINE_logo.svg.png"
                                            alt="gmail.png"
                                            style={{ width: '52px', objectFit: 'cover', objectPosition: '50% 50%' }} />
                                    </div>
                                    <div className="col p-0">
                                        <span>Line ID: numpodang</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-5">
                                <div className="row">
                                    <div className="col-auto">
                                        <img
                                            src="https://static.wixstatic.com/media/86eaf9_597eed6e0725423ea632388eac2e0565~mv2.png/v1/fill/w_52,h_52,al_c,q_85,usm_0.66_1.00_0.01/pin%20(2).webp"
                                            alt="gmail.png"
                                            style={{ width: '52px', objectFit: 'cover', objectPosition: '50% 50%' }} />
                                    </div>
                                    <div className="col p-0">
                                        <span>เลขที่ 98/1 ตำบล แปลงยาว อำเภอ แปลงยาว จังหวัด ฉะเชิงเทรา 24190</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>
    </>)
}