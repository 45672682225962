import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/brand/cst-logo.png';
import logoDark from '../../assets/img/brand/cst-logo-dark.png';

interface Props {
  navClass?: string;
}
const Navbar: React.FC<Props> = (props) => {
  const [navClass, setNavClass] = useState('');
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // window.addEventListener('scroll', () => {
    //   if (props.navClass) return;
    //   let newNavClass = props.navClass || '';
    //   if (window.scrollY >= 200 || window.innerWidth < 768) {
    //     newNavClass = 'scrolled bg-white';
    //   }
    //   console.log('xxx ', newNavClass, navClass);

    //   if (newNavClass !== navClass) {
    //     setNavClass(newNavClass);
    //   }
    // });
    window.addEventListener('resize', () => {
      if (props.navClass) return;
      let newNavClass = props.navClass || '';
      if (window.innerWidth <= 768) {
        newNavClass = 'scrolled bg-white';
      }
      if (newNavClass !== navClass) {
        setNavClass(newNavClass);
      }
    });
    if (window.innerWidth < 768) {
      setNavClass('scrolled bg-white');
    } else {
      setNavClass(props.navClass || '');
    }
  });
  return (
    <nav className={`navbar navbar-expand-sm ${navClass}`}>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          {navClass ?
            <img className="navbar-brand-dark common" src={logo} height="35" alt="Logo light" />
            :
            <img className="navbar-brand-dark common" src={logoDark} height="35" alt="Logo dark" />
          }
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return 'open';
            return '';
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return 'show';
            return '';
          })()}`}
        >
          <ul className='navbar-nav'>
            <li className="nav-item pt-2 pb-2">
              <a href="/" className="nav-link">หน้าแรก</a>
            </li>
            <li className="nav-item pt-2 pb-2">
              <a href="/#Service" className="nav-link">บริการของเรา</a>
            </li>
            <li className="nav-item pt-2 pb-2">
              <a href="/product" className="nav-link">สินค้า</a>
            </li>
            <li className="nav-item pt-2 pb-2">
              <a href="/about" className="nav-link">เกี่ยวกับเรา</a>
            </li>
            <li className="nav-item pt-2 pb-2">
              <a href="/contact" className="nav-link">ติดต่อเรา</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
