import { OurService } from '../components/OurService';
import bgImage from '../../assets/img/home_bg.jpg';
import { Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import products from '../products.json';
import { encodeURL } from '../helper/utils';

export const Home = () => {
    return (
        <Fragment>
            <Header />
            <main>
                <section id='home'>
                    <div>
                        <div
                            className='home-content p-5'
                            style={{ backgroundImage: `url(${bgImage})` }}
                        >
                            <div className='intro container text-center text-light'>
                                <h1 className='title'>CST SOLUTION COMPANY LIMITED</h1>
                                <h2 className="lead">จัดจำหน่ายและบริการติดตั้ง ระบบเน็ตเวิร์ค ระบบแลน ระบบ Firewall</h2>
                                <p className="lead">ช่วยให้ธุรกิจของคุณมีเทคโนโลยีทันสมัย ทำงานได้สำเร็จตามเป้าหมาย ป้องกันความเสียหายของข้อมูล</p>
                            </div>
                        </div>
                    </div>
                </section>
                <OurService />
                <section className="section section-lg pt-0">
                    <div className='container pt-2 pb-5'>
                        <div className='section-header pt-5 pb-5 text-center'>
                            <h3 className='section-title'>
                                สินค้าแนะนำ
                            </h3>
                        </div>
                        <div className='section-content'>
                            <div className="row">
                                <div className="col-md-auto col-sm-12 mb-3">
                                    <div className="card border-0 text-white" style={{ maxWidth: '210px' }}>
                                        <img src="/assets/img/idcard-reader.png" className="card-img" alt="..." />
                                        <div className="card-img-overlay p-0" style={{ backgroundColor: 'rgba(0,0,0,.5)' }}>
                                            <div className="vertical-center pl-2 pr-2">
                                                <h5 className="card-title">เครื่องอ่านบัตร Smart Card</h5>
                                                <a href="./product"
                                                    target="_self" className="btn white is-outline"
                                                    style={{
                                                        borderRadius: '99px',
                                                        color: '#fff',
                                                        border: '2px solid currentColor',
                                                        lineHeight: '2.19em',
                                                        padding: '0 1.2em'
                                                    }}>
                                                    <span>ดูสินค้าทั้งหมด</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        {products.filter(i => i.categoryId === 21 || i.categoryId === 22).map(product =>
                                            <div className="col-md-3 col-6">
                                                <a href={`/product/${encodeURL(product.productName)}`}>
                                                    <div className="card bg-white mb-4">
                                                        <img className="card-img-top" src={product.productImg} alt="No display" />
                                                        <div className="px-3 px-lg-3 py-3">
                                                            <div className="font-weight-normal product-card-name">
                                                                {product.productName}
                                                            </div>
                                                            <div className="product-card-price">
                                                                {new Intl.NumberFormat().format(product.price)}.-
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="AboutUs" className="section section-lg pt-4 pb-4">
                    <div className="container">
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 text-center">
                                <h2 className="h1 px-lg-5">ส่วนหนึ่งของลูกค้าที่ไว้ใจเรา</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo"
                                        src="./assets/img/our-customer/factory-outlet-logo.png" alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo" src="./assets/img/our-customer/grand-home-logo.png"
                                        alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo" src="./assets/img/our-customer/interlink-logo.png"
                                        alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo" src="./assets/img/our-customer/tisco-logo.png"
                                        alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo" src="./assets/img/our-customer/mitr-phol-logo.png"
                                        alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo" src="./assets/img/our-customer/it-city-log.png"
                                        alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo"
                                        src="./assets/img/our-customer/materia-world-logo.png" alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo"
                                        src="./assets/img/our-customer/kingpower-logo.png" alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo"
                                        src="./assets/img/our-customer/nakonluang-logo.png" alt="Card cap" />
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-lg-2">
                                <div className="mb-4">
                                    <img className="card-img-top card-img-logo"
                                        src="./assets/img/our-customer/meungthaicapital-logo.png" alt="Card cap" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>);
}
