import React from 'react';

export const OurService = () => {
  return (
    <section id="Service" className="section section-lg pt-0">
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            บริการของเรา
          </h3>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-network-wired' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Connectivity & IOT</h5>
                  <div className='service-description'>
                    <ul className="p-0 pl-4">
                      <li className="mb-2">
                        Switches
                      </li>
                      <li className="mb-2">
                        Wireless
                      </li>
                      <li className="mb-2">
                        Cisco Meraki
                      </li>
                      <li className="mb-2">
                        Edge Gateway
                      </li>
                      <li className="mb-2">
                        Conference
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-shield-alt' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Cyber Security</h5>
                  <div className='service-description'>
                    <ul className="p-0 pl-4">
                      <li className="mb-2">
                        <div>Firewall & Log</div>
                      </li>
                      <li className="mb-2">
                        <div>MultiFactor Authentication</div>
                      </li>
                      <li className="mb-2">
                        <div>Endpoint Protection</div>
                      </li>
                      <li className="mb-2">
                        <div>Mobile Device Management</div>
                      </li>
                      <li className="mb-2">
                        <div>Personal Data Protection Act (PDPA)</div>
                      </li>
                      <li className="mb-2">
                        <div>PSSL VPN</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-desktop' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Client Computing</h5>
                  <div className='service-description'>
                    <ul className="p-0 pl-4">
                      <li className="mb-2">
                        <div>Corporate PC & NB</div>
                      </li>
                      <li className="mb-2">
                        <div>Apple</div>
                      </li>
                      <li className="mb-2">
                        <div>Microsoft Surface</div>
                      </li>
                      <li className="mb-2">
                        <div>Printing & Scanning</div>
                      </li>
                      <li className="mb-2">
                        <div>Monitor</div>
                      </li>
                      <li className="mb-2">
                        <div>UPS</div>
                      </li>
                      <li className="mb-2">
                        <div>Thai ID Card Reader</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}