import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router';
import ProductDetail from './components/ProductDetail';
import { About, Home, Product, Contact } from './view';

export const App: React.FC = () => {
    return (<>
        <Switch>
            <Route exact path={"/"} component={Home} />
            <Route exact path={"/about"} component={About} />
            <Route exact path={"/product"} component={Product} />
            <Route exact path={"/product/:urlPath"} component={ProductDetail} />
            <Route exact path={"/contact"} component={Contact} />
        </Switch>
    </>);
}
