
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { encodeURL } from '../helper/utils';

import products from '../products.json';

const ProductDetail: React.FC = (props) => {
    const params = useParams<{ urlPath: string }>();
    const urlPath = params.urlPath;
    const product = products.filter(item => encodeURL(item.productName) === urlPath)[0];

    return (
        <Fragment>
            <Header navClass="bg-white" />
            <main className="content-container">
                <section className="section section-lg pt-4 pb-4 mb-6">
                    <div className="container" style={{ maxWidth: '1176px' }}>
                        {!product ?
                            <div className="container">Page Not found</div>
                            : <>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item text-dark" aria-current="page">
                                            <a href="/product">สินค้า</a>
                                        </li>
                                        <li className="breadcrumb-item text-dark" aria-current="page">{product.productName}</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="col-12 col-md-5">
                                        <img className="card-img-top" src={product.productImg} alt="No display" />
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <div>
                                            <h4 className="page-title product-name">
                                                {product.productName}
                                            </h4>
                                        </div>
                                        <div className="product-card-price mb-2">
                                            {'ราคา '}
                                            <span className="h4 product-card-price">{product.price && new Intl.NumberFormat().format(product.price)}.-</span>
                                        </div>
                                        <p style={{ whiteSpace: 'pre-wrap', lineHeight: 2 }}>
                                            {product.detail}
                                        </p>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a href="#" target="_blank" rel="noopener noreferrer">
                                            <img
                                                className="alignnone wp-image-1324 size-full lazyloaded"
                                                src="/assets/img/datasheet-icon.png"
                                                alt="datasheet" width="40" height="40" data-ll-status="loaded" />
                                        </a>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a href="#" target="_blank" rel="noopener noreferrer">
                                            <img
                                                className="alignnone wp-image-1324 size-full lazyloaded"
                                                src="/assets/img/sdk-code-icon.png"
                                                alt="datasheet" width="40" height="40" data-ll-status="loaded" />
                                        </a>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </section>
            </main>
            <Footer />
        </Fragment>

    )
}
export default ProductDetail;