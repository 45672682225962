import React from 'react';

import NavBar from './Navbar';
interface Props {
  navClass?: string;
}

const header: React.FC<Props> = (props) => {
  return (
    <div className='header'>
      <NavBar navClass={props.navClass} />
    </div>
  );
};

export default header;
